.large-tooltips {
  .tooltip-inner {
    width: 25rem;
    max-width: unset;
  }
}

.text-left {
  .tooltip-inner {
    text-align: left;
    padding: .5rem 1rem;
    margin: 0;
    font-size: .9rem;
  }
}
