@each $name, $color in $colors {
  .bg-#{"" + $name} {
    background-color: rgba(var(--#{$name}-color-rgb), 1) !important;
  }
  .bg-#{"" + $name}-light {
    background-color: rgba(var(--#{$name}-color-rgb), .1) !important;
  }
}

@each $name, $color in $backgrounds {
  .background-#{"" + $name} {
    background-color: var(--#{$name}-bg-color) !important;
  }
}

