.etop-table,
etop-table-new {
    .etop-table-header {
        th {
            font-weight: normal;
            border-right: 1px solid #fff;
            border-top: none;
            padding: 0.5rem 0.75rem;
            vertical-align: middle;
            background: var(--primary-color);
            color: white;
        }
    }
}

.table-header-text {
    font-size: 0.8rem;
    height: 25px;
    align-items: center!important;
    display: flex!important;
}

.table-row {
    background-color: #fff;
    margin-bottom: 10px;
    vertical-align: middle;
    border-radius: 5px;
    min-height: 60px;
    cursor: pointer;
    &.row {
        margin-left: 0;
        margin-right: 0;
    }
    .fa-minus {
        color: #ddd;
    }
}

etop-table-new {
    .table-row {
        td {
            position: relative;
        }
        &.active {
            box-shadow: inset 5px 0 0 var(--primary-color);
            margin-left: 0;
            border-left: none;
            // margin-left: -5px;
            // box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.07);
            // border-bottom: 2px solid rgb(247 247 247 / 72%);
            // background-color: rgba(0, 0, 0, 0.01);
            // td:first-child {
            //     div:first-child:before {
            //         content: "";
            //         display: block;
            //         width: 10px;
            //         height: 100%;
            //         left: 0;
            //         top: 0;
            //         border-radius: 6px 0 0 6px;
            //         position: absolute;
            //         border-left: 5px solid var(--primary-color);
            //     }
            // }
        }
    }
}