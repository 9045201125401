.form-control {
  &:focus {
    border-color: var(--primary-color);
    box-shadow: none;
  }

  &.error {
    border-color: var(--danger-color);
  }
}

input[type='checkbox'].input-custom {
  width: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 18px;
  border: 1px solid #95a5a6;
  border-radius: 3px;
  vertical-align: middle;
  cursor: pointer;
}

input[type='checkbox'].input-custom:focus {
  outline: none !important;
}

input[type='checkbox'].input-custom:checked {
  background-color: #fff;
}

input[type='checkbox'].input-custom:checked:after {
  width: 6px;
  height: 9px;
  margin-left: 5px;
  margin-top: 3px;
  border: solid #6e6e6e;
  border-width: 0 2px 2px 0;
  position: absolute;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: '';
  display: inline-block;
}

.mat-form-field {
  width: 100% !important;
}

.mat-form-field-infix textarea {
  padding: 0 5px !important;
  line-height: 16px;
}

.input-material {
  border-radius: 5px;
  outline: none;
  padding: 3px 10px;
  color: #2c3e50;
  border: 1px solid #e6e6e6;

  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &:focus {
    border: 1px solid #33b7d5;
  }
}

.input-pos {
  border: none;
  border-bottom: 1px solid #ddd;
  outline: none;
  width: 100%;
  border-radius: 0;
}

input {
  color: #6e6e6e;

  &::placeholder {
    font-weight: 400;
    opacity: 0.8;
  }
}

.input-full {
  width: 100%;
}
