#toast-container {
  position: fixed !important;
  top: unset !important;
  bottom: 15px !important;
  right: 15px !important;
  z-index: 9999;
}

#toast-container > .toast {
  background-image: none !important;
  margin: 0 0 7px !important;
  padding: 15px 20px !important;
  opacity: 1 !important;
  font-size: 14px;
  box-shadow: none !important;
  width: 400px;
}

#toast-container > .toast:before {
  font-family: "FontAwesome";
  float: left;
  font-size: 14px;
  color: #FFF;
  padding-right: 10px;
}

#toast-container > .toast-warning:before {
    content: "\f071";
}
#toast-container > .toast-error:before {
    content: "\f06a";
}
#toast-container > .toast-info:before {
    content: "\f05a";
}
#toast-container > .toast-success:before {
    content: "\f05d";
}

@media (max-width: 767px) {
  #toast-container {
    position: fixed !important;
    top: 0 !important;
    bottom: unset !important;
    right: unset !important;
     > div {
       width: 100% !important;
       border-radius: 0;
     }
  }
}

@media print {
  #toast-container {
    display: none !important;
  }
}
