@import '@ctrl/ngx-emoji-mart/picker';
.content {
    background-color: var(--gray-color);
}

@media (min-width: 1920px) {
    .col-sxl-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
}

.row-gap-2 {
    row-gap: 1rem;
}