@each $name, $color in $colors {
  .btn-#{"" + $name} {
    background: var(--#{"" + $name}-color);
    border: 1px solid;
    border-radius: 8px;
    border-color: var(--#{"" + $name}-color) !important;
    padding: 0.5rem 2.5rem;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    mat-icon {
      width: 1.25rem;
      height: 1.25rem;
      font-size: 1.25rem;
    }
    > * {
      color: white;
    }
    .mat-progress-spinner circle, .mat-spinner circle {
      stroke: white !important;
    }

    &.dropdown-toggle:after {
      color: white;
      vertical-align: middle;
    }

    &:hover {
      background: var(--#{"" + $name}-color);
      color: white;
    }

    &:active {
      background: var(--#{"" + $name}-color) !important;
    }

    &:focus {
      box-shadow: none !important;
    }

    &:disabled {
      background: var(--#{"" + $name}-color) !important;
      cursor: not-allowed;
      opacity: .8;
    }

    &.btn-outline {
      background: transparent;
      color: var(--#{"" + $name}-color);
      > * {
        color: var(--#{"" + $name}-color);
      }

      .mat-progress-spinner circle, .mat-spinner circle {
        stroke: var(--#{$name}-color) !important;
      }

      &.dropdown-toggle:after {
        color: var(--#{"" + $name}-color);
      }

      &:disabled {
        background: transparent !important;
      }

      &:hover {
        color: var(--#{"" + $name}-color);
      }

    }
  }
  @media (max-width: 1024px){
    .btn-#{"" + $name} {
      padding: 0.5rem 1.5rem;
    }
  }
}


.btn-full {
  width: 100%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 16px;
  margin: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  transition: .2s;
}

.slider-toggle:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 1px;
  bottom: 1px;
  background-color: #ccc;
  transition: .2s;
}

input:checked+.slider-toggle {
  background-color: white;
  border: 1px solid var(--primary-color);
}

input:focus+.slider-toggle {
  box-shadow: 0 0 1px white;
}

input:checked+.slider-toggle:before {
  transform: translateX(20px);
  background-color: var(--primary-color);
}

/* Rounded sliders */
.slider-toggle.round {
  border-radius: 34px;
  border: 1px solid #ccc;
}

.slider-toggle.round:before {
  border-radius: 50%;
}

.topship-toggle {
  input:checked+.slider-toggle {
    border: 1px solid var(--primary-color);
  }

  input:checked+.slider-toggle:before {
    background-color: var(--primary-color);
  }
}

.icon-dropdown {
    border: 1px solid var(--primary-color);
    border-radius: 50%;
    padding: 3px 4px;
    width: 18px;
    height: 18px;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

button:active {
  background: none !important;
}

.show > .btn-primary.dropdown-toggle {
  color: unset;
  background-color: unset;
  border-color: unset;
}

.close-button-with-icon {
  border: 1px solid #856404;
  border-radius: 20px;
  padding: 4px 20px;
  cursor: pointer;
}
