@each $name, $color in $borders {
  .border-#{"" + $name} {
    border: 1px solid var(--#{$name}-border-color) !important;
  }
}

.border-none {
  border: none !important;
}

.e-border {
  border: 1px solid var(--gray-color);
}

.e-border-top {
  border-top: 1px solid var(--gray-color);
}

.e-border-bottom {
  border-bottom: 1px solid var(--gray-color);
}

.e-border-right {
  border-right: 1px solid var(--gray-color);
}

.e-border-left {
  border-left: 1px solid var(--gray-color);
}
