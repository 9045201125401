@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap&subset=vietnamese");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700,800&display=swap&subset=vietnamese");

* {
  font-size: var(--font-size);
  font-family: 'Roboto', sans-serif;
}

::placeholder {
  font-size: var(--font-size);
}

@media screen and (max-width: 1300px) {
  * {
    font-size: 12px;
  }

  ::placeholder {
    font-size: 12px;
  }
}

@media screen and (max-width: 1200px) {
  * {
    font-size: 11px;
  }

  ::placeholder {
    font-size: 11px;
  }
}

@media screen and (max-width: 1024px){
  * {
    font-size: 10px;
  }

  ::placeholder {
    font-size: 10px;
  }
}
