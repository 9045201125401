.status {
  &::before {
    content: "";
    display: inline-block;
    margin-right: 4px;
    background-color: var(--default-color);
    padding: 3px;
    position: relative;
    top: -1px;
    border-radius: 100%;
  }
  color: var(--default-color) !important;
}

@each $name, $color in $status {
  .status {
    &.status-#{"" + $name} {
      &::before {
        background-color: var(--#{"" + $color}-color);
      }
      color: var(--#{"" + $color}-color) !important;
    }
  }
}

/* deprecated */
.order-status {
  &::before {
    content: "";
    display: inline-block;
    margin-right: 4px;
    background-color: var(--default-color);
    padding: 3px;
    border-radius: 100%;
  }
  color: var(--default-color) !important;
}

@each $name, $color in $status {
  .order-status {
    &.order-#{"" + $name} {
      &::before {
        background-color: var(--#{"" + $color}-color);
      }
      color: var(--#{"" + $color}-color) !important;
    }
  }
}
