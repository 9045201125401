.mat-dialog-container {
  border-radius: 10px !important;
  min-width: 400px;
}

.mat-dialog-container:has(.p-0) {
  padding: 0;
}

.matdialog-container {
  display: block;
  // min-width: 25vw;
  margin: -24px;
  color: #444;
}

.dialog-header, .dialog-footer {
  padding: 1rem;
}

.dialog-header {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--gray-color);
  position: relative;
  .close-button {
    cursor: pointer;
    position: absolute;
    right: 0;
    height: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    .mat-icon {
      font-size: 1.5rem !important;
      height: 1.5rem !important;
      width: 1.5rem !important;
    }
  }
}

.dialog-title {
  font-weight: 700;
  font-size: 1.5rem;
  flex: 1;
  text-align: center;
}

.dialog-body {
  padding: 1rem 2rem;
}

.dialog-footer {
  border-top: 1px solid var(--gray-color);
}

