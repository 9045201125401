.etop-badge {
  background: map_get($colors, lighter-gray);
  min-width: 35px;
  line-height: inherit !important;
  border-radius: 5px;
  margin: 2px 0;
  padding: 3px 10px;
  font-size: 0.8rem;
  display: inline-block;
  border: 1px solid #f1f1f1;
}

.badge-full {
  width: 100%;
}

@each $name, $color in $colors {
  .badge-#{"" + $name} {
    color: $color;
  }
}

@each $name, $color in $status {
  .badge-#{"" + $name} {
    color: map_get($colors, $color);
  }
}

@each $name, $color in $ticket-status {
  .badge-ticket-#{"" + $name} {
    color: $color;
  }
}

.badge-product{
  background-color: rgba(var(--lighter-gray-color-rgb), 0.6);
  border: 1px solid rgba(var(--lighter-gray-color-rgb), 1);
  padding: 2px 10px;
  border-radius: 5px;
  display: inline-block;
  margin: 2px 0;
  font-size: 0.8rem;
  font-weight: 500;
}

.badge-border {
  border: 1px solid rgba(var(--gray-color-rgb), 1);
}

.noborder {
  border: none !important;
}

.read-only-badge {
  padding: 2px 10px;
  font-size: 1rem;
  display: inline-flex;
}

.header-tab-badge {
  color: #fff;
  padding: 3px 13px;
  border-radius: 20px;
  margin-left: .5rem;
  font-size: .8rem;
}
