.modal-content {
  border-radius: 10px;
}

.modal-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.modal-footer {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.modal-header, .modal-footer {
  border-color: var(--border-gray-color);
}

.modal-xxl {
  max-width: 95% !important;
}

.modal-title {
  font-weight: 700;
  font-size: initial;
  flex: 1;
  text-align: center;
}

.modal-radius {
  .modal-content {
    border-radius: 8px;
    border-color: white;
  }
}
