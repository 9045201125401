select {
  background-color: white;
  outline: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: -3px;
  appearance: none;
  padding-right: 1.75rem;
  color: #6e6e6e;
}

select.select-align-right {
  text-align-last: right;
}

select.label-select {
  border: none;
  outline: none;
  color: #444;
  cursor: pointer;
}
