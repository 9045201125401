@use '@angular/material' as mat;

@include mat.core();
$mat-primary: (
  50 : #e7f6fa,
  100 : #c2e9f2,
  200 : #99dbea,
  300 : #70cce1,
  400 : #52c1da,
  500 : #33b6d4,
  600 : #2eafcf,
  700 : #27a6c9,
  800 : #209ec3,
  900 : #148eb9,
  A100 : #ebf9ff,
  A200 : #b8ebff,
  A400 : #85dcff,
  A700 : #6bd5ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$mat-accent: (
  50 : #fcf0e4,
  100 : #f8d8bd,
  200 : #f3bf91,
  300 : #eea564,
  400 : #ea9143,
  500 : #e67e22,
  600 : #e3761e,
  700 : #df6b19,
  800 : #db6114,
  900 : #d54e0c,
  A100 : #ffffff,
  A200 : #ffdbcd,
  A400 : #ffb79a,
  A700 : #ffa580,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);


$my-theme-primary: mat.define-palette($mat-primary);
$my-theme-accent: mat.define-palette($mat-accent);
$my-theme-warn: mat.define-palette(mat.$red-palette);
$my-theme: mat.define-light-theme($my-theme-primary, $my-theme-accent, $my-theme-warn);

@include mat.all-component-themes($my-theme);

/** TABLE OF CONTENTS
 ----------------------------------------------------------- /
1 - Mat TABS
2 - Mat MENUS
3 - Mat CHIPS
4 - Mat CHECKBOX
5 - Mat RADIO
6 - DANGER Color
7 - TOPSHIP Color
8 - PRIMARY Color
/// END TABLE OF CONTENTS **/


/** 1 - Mat TABS
------------------------------------------------------------*/

.mat-tab-header {
  border-bottom: 1px solid var(--gray-color) !important;
}

.mat-tab-body-wrapper {
  flex: 1;
}

.mat-tab-label {
  height: 3.5rem !important;
  min-width: unset !important;
  padding: 0 5rem 0 0 !important;
  text-align: left !important;
  justify-content: flex-start !important;
}

.centered {
  .mat-tab-label {
    padding: 0 1rem !important;
    text-align: center !important;
    justify-content: center !important;
  }
}

.equaled {
  .mat-tab-label {
    flex: 1;
  }
}

.mat-tab-label-active {
  opacity: 1 !important;
}

.mat-ink-bar {
  height: 3px !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--primary-color);
}

/// END Mat TABS **/

/** 2 - Mat MENUS
------------------------------------------------------------*/

.mat-menu-item {
  font-family: inherit !important;
  font-size: 1rem !important;
  padding: .9rem 1.25rem !important;
  line-height: unset !important;
  height: auto !important;
  .mat-icon {
    font-size: 1.5rem !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
    margin-right: .5rem !important;
    color: inherit;
  }
}

.mat-menu-content {
  padding: 0 !important;
}

.mat-menu-panel {
  min-height: unset !important;
  max-width: unset !important;
  // max-height: 300px !important;
}

/// END Mat MENUS **/

/** 3 - Mat CHIPS
------------------------------------------------------------*/

.mat-chip {
  font-size: 1rem !important;
}

.mat-standard-chip {
  min-height: 18px !important;
}

.mat-chip .mat-chip-remove.mat-icon,
.mat-chip .mat-chip-trailing-icon.mat-icon {
  font-size: 10px !important;
}

.mat-standard-chip .mat-chip-remove.mat-icon {
  width: 10px !important;
  height: 10px !important;
}

mat-chip.mat-standard-chip:not(.mat-chip-selected), .mat-chip.mat-standard-chip::after {
  background-color: #eeeeee;
  color: #444;
}

.mat-chip.mat-standard-chip.mat-chip-selected {
  color: #fff !important;
}

.mat-chip-list-wrapper .mat-standard-chip {
  margin: 2px !important;
}

/// END Mat CHIPS **/

/** 4 - Mat CHECKBOX
------------------------------------------------------------*/

.mat-checkbox-layout {
  white-space: pre-line !important;
  .mat-checkbox-label {
    line-height: normal;
  }
}

.mat-checkbox-inner-container {
  margin-right: .5rem;
}

.mat-checkbox-frame {
  border-width: 1px !important;
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: var(--primary-color) !important;
}

/// END Mat CHECKBOX **/

/** 5 - Mat RADIO
------------------------------------------------------------*/

.mat-radio-label-content {
  line-height: 15px !important;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primary-color) !important;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-inner-circle {
  background-color: var(--primary-color) !important;
}

.mat-radio-container, .mat-radio-outer-circle, .mat-radio-inner-circle {
  width: 17px !important;
  height: 17px !important;
}

.mat-radio-checked .mat-radio-inner-circle {
  transform: scale(0.6) !important;
}

.mat-radio-outer-circle {
  border-width: 1px !important;
}

/// END Mat RADIO **/

/** 6 - DANGER-COLOR
------------------------------------------------------------*/

.error {
  .mat-form-field-appearance-outline {
    &.mat-focused .mat-form-field-outline-thick {
      .mat-form-field-outline-start,
      .mat-form-field-outline-end {
        border-color: var(--danger-color) !important;
      }
      .mat-form-field-outline-gap {
        border-bottom-color: var(--danger-color) !important;
      }
    }
  }
  .mat-form-field-appearance-standard {
    .mat-form-field-ripple {
      background-color: var(--danger-color);
    }
  }

  .mat-input-element {
    caret-color: var(--danger-color)  !important;
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: var(--danger-color) !important;
  }

  .mat-tab-label-active {
    .mat-tab-label-content {
      color: var(--danger-color) !important;
    }
  }

  .mat-form-field.mat-focused.mat-primary .mat-select-arrow,
  &.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: var(--danger-color) !important;
  }

  &.mat-primary .mat-pseudo-checkbox-checked,
  &.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: var(--danger-color) !important;
  }
}

/// END DANGER-COLOR **/

/** 7 - TOPSHIP-COLOR
------------------------------------------------------------*/

.topship {
  .mat-form-field-appearance-outline {
    &.mat-focused .mat-form-field-outline-thick {
      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end {
        border-color: var(--topship-color) !important;
      }
    }
    &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-thick {
      .mat-form-field-outline-gap {
        border-top-color: transparent !important;
      }
    }
  }
  .mat-form-field-appearance-standard {
    .mat-form-field-ripple {
      background-color: var(--topship-color);
    }
  }

  .mat-input-element {
    caret-color: var(--topship-color)  !important;
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: var(--topship-color) !important;
  }

  .mat-tab-label-active {
    .mat-tab-label-content {
      color: var(--topship-color) !important;
    }
  }

  .mat-form-field.mat-focused.mat-primary .mat-select-arrow,
  &.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: var(--topship-color) !important;
  }

  &.mat-primary .mat-pseudo-checkbox-checked,
  &.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: var(--topship-color) !important;
  }
}

/// END TOPSHIP-COLOR **/

/** 8 - PRIMARY-COLOR
------------------------------------------------------------*/

.mat-form-field-appearance-outline {
  &.mat-focused .mat-form-field-outline-thick {
    .mat-form-field-outline-start,
    .mat-form-field-outline-end {
      border-color: var(--primary-color) !important;
    }
    .mat-form-field-outline-gap {
      border-bottom-color: var(--primary-color) !important;
    }
  }
}

.mat-form-field-appearance-standard {
  .mat-form-field-ripple {
    background-color: var(--primary-color) !important;
  }
}

.mat-input-element {
  caret-color: var(--primary-color)  !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--primary-color) !important;
}

.mat-tab-label-active {
  .mat-tab-label-content {
    color: var(--primary-color) !important;
  }
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow,
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--primary-color) !important;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--primary-color) !important;
}

/// END PRIMARY-COLOR **/


.mat-form-field {
  width: 100% !important;
}

.mat-option-text {
  overflow: visible !important;
}

.no-padding {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0 !important;
  }
}

.mat-form-field-infix {
  display: flex !important;
  align-items: center;
  padding: .5rem 0;
  mat-icon {
    width: 1rem;
    height: 1rem;
    font-size: 1rem;
  }
}

.mat-form-field-invalid .mat-input-element,
.mat-warn .mat-input-element {
  caret-color: var(--danger-color) !important;
}

.mat-select-disabled .mat-select-value {
  cursor: not-allowed;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: red !important;
}

// mat-label normal state style
.mat-form-field-label {
  color: rgba(0, 0, 0, .6) !important;
  padding: 0 5px !important;
}

// mat-label error style
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: var(--danger-color) !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

mat-hint {
  padding-top: 12px;
}

.mat-slide-toggle-content {
  font-family: inherit;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--primary-color) !important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(var(--primary-color-rgb), 0.54) !important;
}

.mat-select-trigger {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.mat-select-value {
  display: block !important;
  max-width: unset !important;
}

.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled),
.mat-option.mat-active,
.mat-select-panel .mat-option.mat-selected {
  background: #f5f5f5 !important;
}

.mat-option.mat-option-disabled {
  color: initial !important;
  opacity: .5;
}

.mat-calendar-body-selected {
  color: #fff;
}

.cdk-virtual-scroll-content-wrapper {
  min-width: unset !important;
  width: 100% !important;
}

.mat-icon {
  font-size: 1rem !important;
  width: 1rem !important;
  height: 1rem !important;
  &.mat-small-icon {
    font-size: .5rem !important;
    width: .5rem !important;
    height: .5rem !important;
  }
  &.mat-big-icon {
    font-size: 1.5rem !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
}

.number-formatted {
  overflow-x: auto;
}

.mat-form-field-appearance-outline {
  background-color: white !important;
  border-radius: 8px;
  .mat-form-field-wrapper {
    margin: 0 !important;
  }

  .mat-form-field-label-wrapper {
    top: -0.64375em;
    padding-top: 0.74375em;
  }
  .mat-form-field-infix {
    // padding: 9px 0 !important;
    line-height: 14px !important;
    input {
      padding: 0 5px !important;
    }
  }

  &.mat-form-field-can-float.mat-form-field-should-float {
    .mat-form-field-outline-gap {
      border-top-color: transparent !important;
    }
  }

  &.mat-form-field-invalid.mat-form-field-invalid {
    .mat-form-field-outline-thick {
      color: var(--danger-color) !important;
      opacity: 0.8 !important;
    }
  }

  &.mat-form-field-disabled {
    .mat-form-field-outline {
      cursor: not-allowed;
      background: #fafafa;
      color: #888 !important;
    }
  }

  .mat-form-field-outline-thick  {
    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
      border-width: 2px !important;
    }
  }

  .mat-form-field-outline-start,
  .mat-form-field-outline-end,
  .mat-form-field-outline-gap {
    border-color: var(--border-gray-color) !important;
  }

  .number-formatted {
    padding: 9px 0;
    flex: 1;
  }
}

.mat-form-field-appearance-standard {
  .mat-form-field-outline {
    background: #f5f5f5 !important;
    border-radius: 5px !important;
  }
  .mat-form-field-underline {
    bottom: -3px !important;
    background-color: #e5e5e5 !important;
  }
  .mat-form-field-flex {
    padding-top: 0 !important;
  }
  .mat-form-field-label {
    padding-left: 0 !important;
  }

  .number-formatted {
    height: 15px;
    line-height: 15px;
    width: 100%;
  }

  .mat-form-field-empty.mat-form-field-label,
  input.mat-input-element,
  mat-select {
    height: 1.25rem !important;
    line-height: 1.25rem !important;
    margin-top: 0 !important;
  }

  .mat-select-arrow-wrapper {
    transform: none !important;
  }
}

.disabled {
  cursor: not-allowed !important;
  input, textarea {
    color: #888 !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline {
    background: #f5f5f5 !important;
    border-radius: 5px !important;
  }
}

$colors-tags: (
  default: #999999,
  red: #e74c3c,
  green: #2ecc71,
  blue: #3498db,
  purple: #9b59b6,
  orange: #f39c12,
  grey: #95a5a6,
);

@each $name, $color in $colors-tags {
  .tag-#{"" + $name} {
    .mat-checkbox-background {
      background-color: var(--#{"" + $name}-color) !important;
    }
  }
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: white !important;
  border-radius: 5px;
}