.avatar {
  width: 35px;
  height: 35px;
  border: 1px solid var(--border-gray-color);
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
}
.avatar-small {
  width: 32px;
  height: 32px;
}
.avatar-inactive {
  border-color: var(--border-gray-color);
}
