
@each $name, $color in $colors {
  .text-#{"" + $name} {
    color: var(--#{$name}-color) !important;
    .fa {
      color: var(--#{$name}-color) !important;
    }
    .external-link-icon {
      color: var(--#{$name}-color) !important;
    }
    .mat-progress-spinner circle, .mat-spinner circle {
      stroke: var(--#{$name}-color) !important;
    }
    &.mat-progress-spinner circle, &.mat-spinner circle {
      stroke: var(--#{$name}-color) !important;
    }
  }
}

@each $name, $size in $fonts {
  .text-#{"" + $name} {
    font-size: $size;
  }
}

.text-bold {
  font-weight: 600;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.sub-state {
  font-size: 0.9rem;
  margin-top: 0.33rem;
  background: rgba(var(--default-color-rgb), 0.2);
  border-radius: 5px;
  padding: 0.25rem 1rem;
}

.group-title {
  color: #212529;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #E5E5E5;
  line-height: 0.1em;
  margin: 20px 0 20px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}

.group-title>span {
  background: #fff;
  padding: 0 10px 0 0;
}

.fs-medium {
  font-size: 1.2rem;
}

.font-8 {
  font-size: 8px;
}

.font-9 {
  font-size: 9px;
}

.font-10 {
  font-size: 10px;
}

.font-11{
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

label {
  margin-bottom:  0;
}
