@each $name, $color in $order-source {
  .order-source {
    font-weight: 700;
    &.order-#{"" + $name} {
      color: var(--#{"" + $color}-color) !important;
    }
  }
}

.order-status {
  color: var(--default-color) !important;
}
